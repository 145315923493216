* {
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: black !important;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

.App {
  background-color: black;
}

.App #Content .logo {
  width: 200px;
  height: 200px;
  background-color: black;
}

.App #Content {
  margin: 2% auto 1%;
  text-align: center;
}

.App #Content h1 {
  color: goldenrod;
  font-size: 24px;
  padding-top: 1.5%;
}

.App #Content hr {
  height: 3px;
  border: none;
  background-color: goldenrod;
}

.App #Content p {
  color: #ccc;
  font-size: 17px;
  margin: auto;
  padding: 1% 0 2% 0;
  font-weight: lighter;
}

#removePaading {
  display: block;
  height: 1px;
}

footer {
  padding: 8px 0;
  background-color: black;
  border-top: 2px solid #222;
}

.contact {
  display: block;
  width: max-content;
  padding: 3px 2%;
  margin: 0 auto;
}

.contact i {
  display: inline-block;
  font-size: 25px;
}

.contact p {
  color: #aaa;
  display: inline-block;
  vertical-align: middle;
  padding: 0 7px;
}

.contact i {
  display: inline-block;
  font-size: 25px;
  color: goldenrod;
}

/* Responsive */

@media (max-width: 1800px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
  h1 {
    padding: 1% 0;
  }
  footer p {
    font-size: 13px;
  }
  .contact i {
    font-size: 23px;
  }
  .contact {
    padding: 5px 5%;
  }
  .App #Content {
    margin: 4% auto 4%;
    text-align: center;
  }
  .App #Content p {
    font-size: 14px;
  }
}